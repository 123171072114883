import React, { useEffect } from 'react'
import { useIsMobile } from '../../Customer/CommonComponet/MediaQueries/mediaQueries'
import { Nav, Navbar } from 'react-bootstrap';
import { Container } from 'material-ui-core';
import SideBar from '../SideBar';
import Logo from '../Logo/Logo';
import Header from '../Header';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { connect } from "react-redux";
import { setCustome, getCustomData } from "../../Store";
import "./CommonHeader.css"

function CommonHeader({customRes}) {

const isMobile = useIsMobile();


const pathname = window?.location?.pathname;
const menuIndex = pathname?.indexOf("/menu");
const menuPath = pathname?.substring(menuIndex);
// console.log("ss" , menuPath)

function renderSidebar(pathname) {
  switch (pathname) {
    case "/":
    case "/GiftCard":
    case "/ContactUs":
    case "/OrderHistory":
      return <SideBar />;
    default:
      return null;
  }
}


function renderMenu(pathname) {
  switch (pathname) {
    case "/":
    case "/GiftCard":
    case "/ContactUs":
    case "/OrderHistory":
      return <Header  />;
    default:
      return null;
  }
}

function renderMenu_(pathname) {
  switch (pathname) {
    case  "/menu":
      return <Header  />;
    default:
      return null;
  }
}



useEffect(() => {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?sensor=false&key=${customRes?.map_key}&libraries=places`;
  // console.log("map" ,`https://maps.googleapis.com/maps/api/js?sensor=false&key=${customRes?.map_key}&libraries=places`)
  script.async = true;
  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
}, [customRes]);

  return (
      <Navbar
          className="p-2"
          collapseOnSelect
          expand="lg"
          style={{ 
          position: pathname === "/" && "sticky", 
          top: 0, 
          zIndex: 1000 ,
          height: isMobile ? "" : 75 , 
          background : customRes?.header_footer_section_colour || "#fff"
        }}
        >
            <Navbar.Brand>
            {isMobile && <SideBar />}
            <Logo /> 
            </Navbar.Brand>
              {isMobile &&
                <LanguageSelect  />}
            <Navbar.Collapse className="justify-content-end"> 
            <Header  />

              {/* {
              menuPath === '/menu' ? renderMenu_(menuPath) : renderMenu(pathname)
              } */}
              <Nav className="me-auto">
              </Nav>
              <Nav>
                <div style={{display : isMobile && "none"}} className="my-2">
                <LanguageSelect  />
                </div>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
        )
}


const mapStateToProps = (state) => ({
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonHeader);
