import Swal from 'sweetalert2';
import "./Swal.css"

export function showSuccessNotification(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function showSuccessNotificationText(text) {
  Swal.fire({
    position: 'center-center',
    icon: 'success',
    text: text,
    showConfirmButton: false,
    timer: 1500,
  });
}


export function showSuccessNotificationCustome(message , history) {
      Swal.fire({
            position: "center-center",
            icon: "warning",
            text: `${message}` ,
            showConfirmButton: true,
            confirmButtonText:  `${"Back To Home"}`,
          })
          .then((result) => {
            if (result.isConfirmed) {
              history.push("/");
            }
          });
    }

export function showErrorNotification(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'error',
    title: title,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function showErrorNotificationCart(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'warning',
    title: title,
    showConfirmButton: false,
    timer: 2500,
    showCancelButton : false
  });
}


export function showErrorCouponAndVoucher(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'error',
    title: title,
    showConfirmButton: false,
    timer: 4000,
    showCancelButton : false
  });
}


export function showSuccessTableBook(title) {
  Swal.fire({
    position: 'center-center',
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 1500,
    customClass: {
      title: 'swal-table-book',
    },
  });
}