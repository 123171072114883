import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "./paymentsuccess.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useHistory, useParams } from "react-router-dom";
import api from "../../CommonApi/axios";
import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import OrderDetailsComponent from "./OrderDetailsComponent";
import PaymentProcessingCard from "./PaymentProcessingCard";



function PaymentSuccessDineIn(props) {
  const { customRes } = props;
  let history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  let orderForQr = sessionStorage.getItem("orderForQr");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const { poId } = useParams();
  const { tableId } = useParams();

  const pathname = window.location.href;
  const pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  const url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");
  const Branch_id = String(pathnamesplits[1])
  const TableNo = String(pathnamesplits[2])
  const unique_code = String(pathnamesplits[5]);
  const [OrderDetails, setOrderDetails] = useState("")


  // console.log("orderID" ,TableNo , unique_code ,Branch_id) 

  const Payment = sessionStorage.getItem("Payment");

  const [abcd, setabcd] = useState(Payment ? Payment : false);
  const [Button1, setButton1] = useState(false);

  const myString = unique_code;
  const extractedString = myString?.split('#')[0];

  // console.log(extractedString);

  useEffect(() => {
    if (extractedString && Payment === null) {
      handleSubmit();
    }
  }, [extractedString && Payment === null]);

  let storedItemPrintJSON = localStorage.getItem('itemPrint');

  // console.log("storedItemPrintJSON", storedItemPrintJSON)
  // sessionStorage.setItem("Payment", true);

  function handleSubmit() {
    let item = {
      unique_code: extractedString,
      table_number: TableNo,
      status: "success",
      branch_id: Branch_id
    };
    api
      .patch(`/client/order/updateDineInOrderStatus`, item)
      .then((res) => {

        setabcd(true);
        sessionStorage.setItem("Payment", true);
        //   console.log("success", res.data);
        if (storedItemPrintJSON !== null) {
          let itemPrint = {
            order_id: storedItemPrintJSON
          }

          // console.log("itemPrint", itemPrint)
          // getOrderDetails(extractedString && Branch_id);
          api.post("/client/order/print-order-summary", itemPrint).then((res) => {
            // console.log("res" ,res);
            localStorage.removeItem('itemPrint')
          }).catch((err) => {

          })
        }
      })
      .catch((err) => {
        if (err.response) {
          // console.log("err.response.message", err.response.data.error.message);
          setButton1(true);
          toast.error(err.response.data.error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }


  useEffect(() => {
    if (Payment === "true" && (extractedString && Branch_id)) {
      getOrderDetails(extractedString , Branch_id);
    }
  }, [Payment === "true" && (extractedString && Branch_id)]);

  
  function getOrderDetails(extractedString ,Branch_id) {
      api
        .get(`/client/order/getOrderDetails/${extractedString}/dineIn/${Branch_id}`)
        .then((res) => {
          // console.log("success", res?.data?.data[0]);
          setOrderDetails(res?.data?.data[0])
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
  }

  const downloadFile = (value) => {
    api
      .get(`/client/order/downloadOrderInvoiceForDineIn/${value}`)
      .then((res) => {
        const tableData = res.data.data;
        // window.open(`${tableData}`);
        // console.log("downloadOrderInvoice", tableData);
        window.location.href = `${tableData}`;
      })
      .catch((error) => {
        toast.error(error?.message?.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const location = useLocation();

  useEffect(() => {
    window.history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, [])



  return (
    <React.Fragment>
      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container fluid>
          {abcd === false ? (
            <PaymentProcessingCard />
          ) : (
            <OrderDetailsComponent
              OrderDetails={OrderDetails}
              extractedString={extractedString}
              TableNo={TableNo}
              downloadFile={downloadFile}
              orderID={extractedString}
              PaymentMethod={"Online"}
              customRes={customRes}
              history={history}
            />
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessDineIn);
