import React from 'react'
import {  CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import LinearProgress from "@mui/material/LinearProgress";



export default function Loading({type}) {
  return (
    <React.Fragment>
      {type === "HomePage" ?  
      <LinearProgress /> :
         type === "" ?  
       <Box sx={{ display: 'flex', height : 35, justifyContent: 'center', alignItems: 'center' }}>
       <CircularProgress style={{color : "rgb(242, 80, 66)"}} />
       </Box> :  
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        {type === 'circular' && <CircularProgress color="inherit" />}
        {type === 'linear' && <div>Linear Loader</div>}
      </Backdrop> 
      }
    </React.Fragment>
  )
}

