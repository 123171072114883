import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../paymentsuccess.css";
import { showToastCommon } from "../../CommonComponet/Toastify/Toastify";
import api from "../../../CommonApi/axios";
import CommonHeader from "../../../Comman/CommonHeader.js/CommonHeader";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import PaymentProcessingCard from "../PaymentProcessingCard";
import OrderDetailsComponent from "../OrderDetailsComponent";
import { ToastContainer, toast } from "react-toastify";


function AnspaymentSuccess(props) {
      const { customRes } = props;

      const history = useHistory();
      const location = useLocation();
      const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
      const pathname = window.location.href;
      const pathnamesplits = pathname.replace(/(https?:\/\/)?(www.)?/i, "").split("/");
      const orderID = pathnamesplits[5]?.split('#')[0];
      const Payment = sessionStorage.getItem("Payment");
      const branchId = pathnamesplits[1];

      let orderForQr = sessionStorage.getItem("orderForQr");

      let { search } = useLocation();

      const query = new URLSearchParams(search);
      const TableNo = query.get("no");


      useEffect(() => {
            window.history.pushState(null, null, location.href);
            window.onpopstate = function (event) {
                  history.go(1);
            };
      }, [])
     

      const [loader, setloader] = useState(false);

      // console.log("Payment", Payment === "false")

      const [OrderDetails, setOrderDetails] = useState("")

      const [hostedCheckoutId, setHostedCheckoutId] = useState("");

      useEffect(() => {
            const queryParams = new URLSearchParams(location.search);
            const hostedCheckoutId = queryParams.get("hostedCheckoutId");
            if (hostedCheckoutId) {
                  setHostedCheckoutId(hostedCheckoutId);
            }
      }, [location.search]);


      const [extractedString, setExtractedString] = useState('');
      const [abcd, setabcd] = useState(false);

      useEffect(() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            const pay_unicID = pathnamesplits[6];
            setExtractedString(pay_unicID?.split('#')[0]);
            window.history.pushState(null, null, location.href);
            window.onpopstate = function (event) {
                  history.go(1);
            };

            // if (!sessionStorage.getItem("reloaded")) {
            //       sessionStorage.setItem("reloaded", "true");
            //       window.location.reload(true);
            // }
      }, [orderID, Payment]);

      useEffect(() => {
            if (hostedCheckoutId) {
                  if (Payment === "success") {

                  } else {
                        handleSubmit();
                  }
            }
      }, [hostedCheckoutId])

      function handleSubmit() {
            setabcd(true);
            if (orderID) {
                  let item = { hosted_checkout_id: hostedCheckoutId, order_id: orderID };
                  api.patch(`/client/order/updateANZPaymentStatus`, item)
                        .then(() => {
                              sessionStorage.setItem("Payment", "success");
                              setabcd(false);
                        })
                        .catch((err) => {
                              if (err.response) {
                                    console.log("err", err?.response?.data?.error?.message)
                                    setabcd(false);
                                    showToastCommon(err?.response?.data?.error?.message);
                                    sessionStorage.setItem("Payment", "fail");
                              }
                        });
            }
      }

      function handleSubmitRetry() {
            setloader(true);
            let item = {
                  order_id: orderID
            };
            // console.log(item);
            api
                  .post(`/client/order/paymentReInitializeForANZ`, item)
                  .then((res) => {
                        setloader(false);
                        //     console.log("res1", res?.data?.data?.paymentRes?.redirectUrl);
                        if (res?.data?.data?.paymentRes?.redirectUrl !== "") {
                              window.open(`${res?.data?.data?.paymentRes?.redirectUrl}`, "_self");
                        }
                  })
                  .catch((err) => {
                        setloader(false);
                        if (err.response) {
                              showToastCommon(err?.response?.data?.error?.message)
                        }
                  });
      }

      useEffect(() => {
            if (Payment === "success" && (extractedString && branchId)) {
              getOrderDetails(extractedString , branchId);
            }
          }, [Payment === "success" && (extractedString && branchId)]);

      function getOrderDetails(extractedString ,branchId) {
                  api
                        .get(`/client/order/getOrderDetails/${extractedString}/${orderForQr !== null ? orderForQr : "delivery"}/${branchId}`)
                        .then((res) => {
                              console.log("success", res?.data?.data[0]);
                              setOrderDetails(res?.data?.data[0])
                        })
                        .catch((err) => {
                              if (err.response) {
                                    toast.error(err.response.data.error.message, {
                                          position: "top-center",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "light",
                                    });
                              }
                        });
      }

      return (
            <React.Fragment>
                  <CommonHeader />
                  <div
                        style={{
                              backgroundColor: "#F0EEED",
                              minHeight: "100vh",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              overflowX: "hidden"
                        }}
                  >
                        <Container fluid>
                              {abcd === true && (
                                    <PaymentProcessingCard />
                              )}
                              {abcd === false && Payment === "success" &&
                                    (
                                          <OrderDetailsComponent
                                                OrderDetails={OrderDetails}
                                                extractedString={extractedString}
                                                TableNo={TableNo}
                                                // downloadFile={downloadFile}
                                                orderID={orderID}
                                                customRes={customRes}
                                                history={history}
                                                PaymentMethod={"Online"}
                                          />
                                    )}
                              {abcd === false && Payment === "fail" &&
                                    <Row className="justify-content-sm-center">
                                          <div
                                                className="text-center"
                                                style={{ width: "600px", backgroundColor: "#F0EEED" }}
                                          >
                                                <Card style={{ border: 0, borderRadius: 0 }}>
                                                      <Container>
                                                            <Card.Body>
                                                                  <Card.Title>
                                                                        <div>
                                                                              <img
                                                                                    style={{ height: "80px" }}
                                                                                    src="/assets/media/my/cancel.png"
                                                                                    alt="payment_fail"
                                                                              />
                                                                        </div>
                                                                  </Card.Title>
                                                                  <Row>
                                                                        <Col lg={2}></Col>
                                                                        <Col lg={8}>
                                                                              <Card.Title className="header_success mt-3">
                                                                                    Payment Failed
                                                                              </Card.Title>
                                                                        </Col>
                                                                        <Col lg={2}></Col>
                                                                  </Row>

                                                                  <Row>
                                                                        <Col lg={2}></Col>
                                                                        <Col lg={8}>
                                                                              <Card.Title className="payment_text_info mt-2">
                                                                                    We are sorry that you transaction failed please try
                                                                                    again.
                                                                              </Card.Title>
                                                                        </Col>
                                                                        <Col lg={2}></Col>
                                                                  </Row>

                                                                  <Card.Text>
                                                                        {
                                                                              loader === true ? (
                                                                                    <Box>
                                                                                          <CircularProgress size={50} thickness={5} />
                                                                                    </Box>
                                                                              ) : (
                                                                                    <Button
                                                                                          type="button"
                                                                                          style={{
                                                                                                backgroundColor:
                                                                                                      customRes?.button_colour ?? "#dc3545",
                                                                                                border: 0,
                                                                                          }}
                                                                                          className="custom_hover my-4"
                                                                                          onClick={handleSubmitRetry}
                                                                                    >
                                                                                          <strong
                                                                                                style={{
                                                                                                      color: customRes?.non_highlighted_text ?? "white",
                                                                                                }}
                                                                                          >
                                                                                                Retry
                                                                                          </strong>
                                                                                    </Button>
                                                                              )
                                                                        }
                                                                        <Button
                                                                              type="button"
                                                                              style={{
                                                                                    backgroundColor:
                                                                                          customRes?.button_colour ?? "#dc3545",
                                                                                    border: 0,
                                                                              }}
                                                                              className="custom_hover my-4 mx-2"
                                                                              onClick={() => history.push(`/`)}
                                                                        >
                                                                              <strong
                                                                                    style={{
                                                                                          color: customRes?.non_highlighted_text ?? "white",
                                                                                    }}
                                                                              >
                                                                                    Go Back
                                                                              </strong>
                                                                        </Button>
                                                                  </Card.Text>
                                                            </Card.Body>
                                                      </Container>
                                                </Card>
                                          </div>
                                    </Row>}
                        </Container>
                  </div>
                  <ToastContainer />
            </React.Fragment>
      );
}

const mapStateToProps = (state) => ({
      customRes: state.custom.customRes,
});

const mapDispatchToProps = (dispatch) => {
      return { dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnspaymentSuccess);