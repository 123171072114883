import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, InputGroup } from 'react-bootstrap';
import api from '../../../../CommonApi/axios';
import { showSuccessNotification } from '../../../CommonComponet/Swal/Swal';
import useError from '../../../CommonComponet/Error/useError';
import { LocationRegular, CallRegular, MailRegular } from '@fluentui/react-icons';
import Select from "react-select";
import Button from '@mui/material/Button';
import { ToastContainer } from "react-toastify";
import "./DeliveryDetailsModel.css"
import { customSelectStyles } from './CoomonSelectCss';
import { Errormessage } from '../../../CommonComponet/Error/Errormessage';
import { validateEmail } from '../../../CommonComponet/Email/Email';
import moment from 'moment';
import { showToastCommon } from '../../../CommonComponet/Toastify/Toastify';

function PickupDetailsModel({ showPickup, closePickup, onErrorCheckOutTimeComper, totalAmount, getCartList, customRes }) {


    let sessionBranchID = sessionStorage.getItem("sessionBranchID");
    let pickupInfo = JSON.parse(sessionStorage.getItem("pickupInfo")) || null;
    let slot_type = sessionStorage.getItem("slot_type")
    let deliveryInfo = JSON.parse(sessionStorage.getItem("deliveryInfo")) || null;

    const QrCodeScanAndRedirect = sessionStorage.getItem("QrCodeScanAndRedirect");
    const item_value = JSON.parse(sessionStorage.getItem("items"));
    let is_restricted = sessionStorage.getItem("is_restricted");

    const [pickup_order_time, setpickup_order_time] = useState(null);
    const [pickup_order_date, setpickup_order_date] = useState(null);
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [error, setError] = useState(null);
    const [PickupTimeList, setPickupTimeList] = useState([]);
    const [PickupDays, setPickupDays] = useState([]);
    const [IndexForPickupDate, setIndexForPickupDate] = useState("");
    const [order_date, setorder_date] = useState("");
    const [order_time, setorder_time] = useState("");
    let placingDate1 = sessionStorage.getItem("placingDate");
    let placingTime1 = sessionStorage.getItem("placingTime");
    const cart_id = sessionStorage.getItem("cart_id");

    // Form Submission Function with Validation
    const handleSubmit = (e) => {
        e.preventDefault();

        // Common Data
        const formData = {
            pickup_order_date,
            pickup_order_time,
            fullName,
            phone,
            customerEmail,
            order_date: slot_type === "direct" ? moment(new Date()).add(15, 'minutes').format() : order_date,
            order_time: slot_type === "direct" ? moment(new Date()).add(15, 'minutes').format('LT') : order_time,
            order_type: "pickup",
            PreTime: "25",
        };

        const item = {
            order_type: "pickup",
            PreTime: "25",
            cart_id: cart_id,
            order_date: slot_type === "direct" ? moment(new Date()).add(15, 'minutes').format() : order_date,
            order_time: slot_type === "direct" ? moment(new Date()).add(15, 'minutes').format('LT') : order_time,
            total_amount: totalAmount,
            placingDate: placingDate1 || moment(new Date()).format("YYYY-MM-DD"),
            placingTime: placingTime1 || moment(new Date()).format("h:mm"),
        };



        // Validation
        if (slot_type !== "direct" && !validateFormData(formData)) return;

        // API Call
        processCheckout(item, formData);
    };

    // Validation Function
    const validateFormData = ({ pickup_order_date, pickup_order_time, fullName, phone, customerEmail }) => {
        if (!pickup_order_date) return setError("Please select order date");
        if (!pickup_order_time) return setError("Please select order time");
        if (!fullName) return setError("Please enter your name");
        if (!phone) return setError("Please enter your phone number");
        if (!validateEmail(customerEmail)) return setError("Please enter a valid email");
        return true;
    };

    // API Call Function
    const processCheckout = (item, formData) => {
        api
            .post(`/client/cart/processedForCheckOut/${cart_id}`, item)
            .then(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                showSuccessNotification("Pickup details saved successfully");
                sessionStorage.setItem("pickupInfo", JSON.stringify(formData));
                sessionStorage.removeItem("deliveryInfo");
                setError("");
                closePickup();
                getCartList();
            })
            .catch((err) => handleApiError(err));
    };

    // Error Handling Function
    const handleApiError = (err) => {
        if (err.response) {
            const errorMessage = err?.response?.data?.error?.message;
            if (typeof errorMessage === "string") {
                setError(errorMessage);
            } else if (Array.isArray(errorMessage)) {
                onErrorCheckOutTimeComper(errorMessage);
                showToastCommon("The selected products are not available for this order. Please remove unavailable items to proceed");
                closePickup();
            }
        }
    };


    useEffect(() => {
        if (pickupInfo !== null) {
            setCustomerEmail(pickupInfo?.customerEmail || "");
            setFullName(pickupInfo?.fullName || "");
            setPhone(pickupInfo?.phone || "");

            if (QrCodeScanAndRedirect || is_restricted) {
                setpickup_order_date(pickupInfo?.pickup_order_dateA || "");
                setpickup_order_time(pickupInfo?.pickup_order_timeA || "");
                setorder_date(pickupInfo?.order_date || "")
                setorder_time(pickupInfo?.order_time || "")
            } else {
                setpickup_order_date(pickupInfo?.pickup_order_date || "");
                setpickup_order_time(pickupInfo?.pickup_order_time || "");
            }
        }
    }, [pickupInfo !== null])



    useEffect(() => {
        if (deliveryInfo !== null) {
            setCustomerEmail("");
            setFullName("");
            setPhone("");

            if (QrCodeScanAndRedirect || is_restricted) {
                setpickup_order_date("");
                setpickup_order_time("");
                setorder_date("")
                setorder_time("")
            } else {
                setpickup_order_date("");
                setpickup_order_time("");
            }
        }
    }, [deliveryInfo !== null])


    const Star = <span style={{ color: "red" }}>*</span>


    useEffect(() => {
        setPickupDays([]);
        setPickupTimeList([]);
        let item = {
            type: "pickup",
            branch_id: sessionBranchID,
            language: "EN",
        };
        api
            .post(`/client/order/branchtimefilter`, item)
            .then((res) => {
                // setbranchTimePickup(res.data.data);
                setPickupDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
                const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList)) : [];
                const timeList = dayList.timeList;
                // console.log('daylist' ,res.data.data?.find(item => Array.isArray(item.dayList))) 
                setPickupTimeList(timeList ? timeList : []);
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response?.data?.error?.message);
                }
            });
    }, [sessionBranchID !== null]);

    return (
        <>
            <ToastContainer />
            <Modal centered dialogClassName="modal-method" start show={showPickup} onHide={closePickup}>
                <Form >
                    <Modal.Body className='mx-1'>
                        <Row className='mt-2 mb-4'>
                            <Col lg={12} md={12} xs={12} sm={12}>
                                <h4 className='title'>Pickup Information</h4>
                            </Col>
                        </Row>

                        <Row>

                            {slot_type === "direct" ? "" :
                                <>
                                    <Col lg={6} md={6} xs={12}>
                                        <Form.Label className='delivery_label'>Pickup Date {Star}</Form.Label>
                                        <Select
                                            className='mb-3'
                                            isSearchable={false}
                                            placeholder="Select Date"
                                            isDisabled={(QrCodeScanAndRedirect || is_restricted) ? true : false}
                                            options={
                                                PickupDays?.dayList &&
                                                PickupDays?.dayList?.map((day) => ({
                                                    label: day.label,
                                                    value: day.label,
                                                    id: day.id
                                                }))
                                            }
                                            value={pickup_order_date}
                                            onChange={(opt) => {
                                                const index = PickupDays?.dayList?.findIndex(day => day.label === opt.label);

                                                if (opt.value.includes("TODAY")) {
                                                    setorder_date(moment().format());
                                                } else if (opt.value.includes("TOMMORROW")) {
                                                    const tomorrowDate = moment().add(1, 'day');
                                                    setorder_date(tomorrowDate.format());
                                                } else {
                                                    setorder_date(moment(opt).format());
                                                }
                                                setIndexForPickupDate(index);
                                                setpickup_order_date(opt);

                                            }}
                                            styles={customSelectStyles()}
                                        />
                                    </Col>
                                    <Col lg={6} md={6} xs={12}>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label className='delivery_label'>Pickup Time {Star}</Form.Label>
                                            <Select
                                                isSearchable={false}
                                                placeholder="Select Time"
                                                isDisabled={(QrCodeScanAndRedirect || is_restricted) ? true : false}
                                                styles={customSelectStyles()}
                                                options={
                                                    PickupTimeList[IndexForPickupDate] &&
                                                    PickupTimeList[IndexForPickupDate]?.map((time) => ({
                                                        label: time,
                                                        value: time,
                                                    }))
                                                }
                                                value={pickup_order_time}
                                                onChange={(opt) => {
                                                    setpickup_order_time(opt);
                                                    setorder_time(opt.value)
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </>
                            }
                            <Col lg={12} md={12} xs={12} sm={12}>
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Full Name {slot_type === "direct" ? "" : Star}</Form.Label>
                                    <Form.Control
                                        id="Enter your full name"
                                        type={"text"}
                                        placeholder="Enter your full name"
                                        className='input_model'
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Phone {slot_type === "direct" ? "" : Star}</Form.Label>
                                    <div className="position-relative">

                                        <span
                                            className="position-absolute translate-middle top-50 start-0 ms-4"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <CallRegular className='icon_' />
                                        </span>
                                        <Form.Control
                                            id="Phone number"
                                            type={"number"}
                                            placeholder="Enter your Phone number"
                                            style={{ paddingLeft: '45px' }}
                                            className='input_model'
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Email {slot_type === "direct" ? "" : Star}</Form.Label>
                                    <div className="position-relative">
                                        <Form.Control
                                            id="Enter your email"
                                            type={"email"}
                                            placeholder="Enter your email"
                                            style={{ paddingLeft: '45px' }}
                                            className='input_model'
                                            value={customerEmail}
                                            onChange={(e) => setCustomerEmail(e.target.value)}
                                        />
                                        <span
                                            className="position-absolute translate-middle start-0 ms-4"
                                            style={{ cursor: 'pointer', top: 22 }}
                                        >
                                            <MailRegular className='icon_' />
                                        </span>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Errormessage error={error} />
                        <Row className='mt-3'>
                            <Col lg={6} md={6} xs={6}>
                                <Button type="button" className='Cancle_button' variant="outlined"
                                    onClick={(e) => {
                                        closePickup()
                                        setError("");
                                        if (pickupInfo === null) {
                                            setCustomerEmail("");
                                            setFullName("");
                                            setIndexForPickupDate("");
                                            setPhone("");
                                            setorder_date("");
                                            setorder_time("");
                                            setpickup_order_date("");
                                            setpickup_order_time("");
                                        }
                                    }}
                                >
                                    Cancel
                                </Button >
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Button style={{
                                    backgroundColor: customRes?.button_colour ? customRes?.button_colour : "#dc3545",
                                    color: customRes?.non_highlighted_text || "#fff"

                                }} onClick={(e) => handleSubmit(e)} type="submit"
                                    className='Aplly_button'
                                    variant="contained">
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    );
}

export default PickupDetailsModel;

