import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, InputGroup } from 'react-bootstrap';
import api from '../../../../CommonApi/axios';
import { showSuccessNotification } from '../../../CommonComponet/Swal/Swal';
import useError from '../../../CommonComponet/Error/useError';
import { LocationRegular, CallRegular, MailRegular } from '@fluentui/react-icons';
import Select from "react-select";
import Button from '@mui/material/Button';
import PlacesAutocomplete from "react-places-autocomplete";
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from "react-places-autocomplete";
import "./DeliveryDetailsModel.css"
import { customSelectStyles } from './CoomonSelectCss';
import { googleMapsApiKey } from '../../../../CommonApi/googelApiKey';
import { Errormessage } from '../../../CommonComponet/Error/Errormessage';
import { validateEmail } from '../../../CommonComponet/Email/Email';
import moment from 'moment';
import { showToastCommon } from '../../../CommonComponet/Toastify/Toastify';
import { ToastContainer } from "react-toastify";


function DeliveryDetailsModel({ showDelivery, closeDelivery, onErrorCheckOutTimeComper, totalAmount, getCartList ,customRes}) {

    let sessionBranchID = sessionStorage.getItem("sessionBranchID");
    let deliveryInfo = JSON.parse(sessionStorage.getItem("deliveryInfo")) || null;
    let pickupInfo = JSON.parse(sessionStorage.getItem("pickupInfo")) || null;
    const QrCodeScanAndRedirect = sessionStorage.getItem("QrCodeScanAndRedirect");
    const item_value = JSON.parse(sessionStorage.getItem("items"));
    let is_restricted = sessionStorage.getItem("is_restricted");
    // console.log('deliveryInfo' ,deliveryInfo)
    const [IndexForDelievryDate, setIndexForDelievryDate] = useState("");
    // Inside your component
    const [address_line1, setaddress_line1] = useState("");
    const [address_line2, setaddress_line2] = useState("");
    const [order_date, setorder_date] = useState("");
    const [order_time, setorder_time] = useState("");
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [DeliveryError, setDeliveryError] = useState("");
    const [delivery_order_time, setdelivery_order_time] = useState(null);
    const [delivery_order_date, setdelivery_order_date] = useState(null);

    let placingDate1 = sessionStorage.getItem("placingDate");
    let placingTime1 = sessionStorage.getItem("placingTime");

    const [branchId_, setbranch_Id] = useState(sessionBranchID);
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [pincode, setpincode] = useState("");
    const [DeliveryDays, setDeliveryDays] = useState([]);
    const [DeliveryTimeList, setDeliveryTimeList] = useState([]);
    const [error, setError] = useState(null);

    const Star = <span style={{ color: "red" }}>*</span>

    function handleChangeAddress(address) {
        setaddress_line1(address);
        // console.log(address);
    }

    const cart_id = sessionStorage.getItem("cart_id");

    const handleSelect = async (address) => {
        setDeliveryError(null);
        if (address !== "" && lat !== "") {
            setError(null);
        }
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        setaddress_line1(address);
        setLat(latLng.lat);
        setLng(latLng.lng);
        const address_ = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${customRes?.map_key}`;
        fetch(address_)
            .then((res) => res.json())
            .then((resJson) => {
                const results = resJson?.results;
                let address = results[0].address_components;
                let zipcode = address[address.length - 1].long_name;
                // let country = address[address.length - 2].long_name;
                // let state = address[address.length - 3].long_name;
                // let city = address[address.length - 4].long_name;
                getDeliveryCost(latLng.lat, latLng.lng, zipcode);
                // console.log("sas", latLng.lat, latLng.lng, zipcode);
                // setstate();
                // setcity();
                // setcountry();
                setpincode(zipcode);
            })
            .catch((error) => { });
    };

    function getDeliveryCost(latitude, longitude, pincode) {
        setError(null);
        const data = {
            branch_id: branchId_,
            lat: latitude.toString(),
            long: longitude.toString(),
            pincode: pincode,
            order_type: "delivery",
        };
        api
            .post(`/client/order/checkForDelivery`, data)
            .then((res) => {
                setError(null);
            })
            .catch((err) => {
                if (err.response) {
                    // console.log("error", err.response?.data);
                    setDeliveryError("Does not deliver to this location.");
                    setError("We don't deliver to the selected address.");


                    // setTimeout(() => {
                    //   selDeliveryError(null);
                    // }, 2000);
                }
            });
        // setOrderCostData(data);
        // getOrderCost();
    }

    useEffect(() => {
        // if (branchId_ !== "" && is_restricted) {
        setDeliveryDays([]);
        setDeliveryTimeList([]);
        let item = {
            type: "delivery",
            branch_id: branchId_,
            language: "EN",
        };
        api
            .post(`/client/order/branchtimefilter`, item)
            .then((res) => {
                // setbranchTime(res.data.data);
                setDeliveryDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
                const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList)) : [];
                const timeList = dayList.timeList;
                // console.log('timeList' ,timeList) 
                setDeliveryTimeList(timeList ? timeList : []);
                //   setCheckDelivery(false)
                // console.log("delivery_data", res.data.data);
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response?.data?.error?.message);
                }
            });
        // }
    }, [branchId_]);


    // Form Submission Function with Validation
    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic Validation
        if (!address_line1) {
            setError("Please enter delivery address");
            return;
        }
        if (!delivery_order_date) {
            setError("Please select order date");
            return;
        }
        if (!delivery_order_time) {
            setError("Please select order time");
            return;
        }
        if (!fullName) {
            setError("Please enter your name");
            return;
        }
        if (!phone) {
            setError("Please enter your phone number");
            return;
        }

        if (!validateEmail(customerEmail)) {
            setError("Please enter a valid email");
            return;
        }


        // Data to Store in Session Storage
        const formData = {
            address_line1,
            address_line2,
            placingDate: String("2024-11-15"),
            placingTime: String("07:30"),
            delivery_order_time,
            delivery_order_date,
            fullName,
            phone,
            customerEmail,
            order_date,
            order_time,
            lat: String(lat),
            lng: String(lng),
            order_type: "delivery",
            PreTime: "25",
            pincode
        };

        const item = {
            order_type: "delivery",
            PreTime: "25",
            cart_id: cart_id,
            address_line1,
            address_line2,
            lat: String(lat),
            long: String(lng),
            order_date,
            order_time,
            pincode: pincode,
            total_amount: totalAmount,
            placingDate: placingDate1 ? placingDate1 : String(moment(new Date()).format("YYYY-MM-DD")),
            placingTime: placingTime1 ? placingTime1 : String(moment(new Date()).format("h:mm")),
        };

        // console.log("item", item);
        // return

        // onErrorCheckOutTimeComper(["66854a3ce9f39954e4669916"]);

        api
            .post(`/client/cart/processedForCheckOut/${cart_id}`, item)
            .then((res) => {
                window.scrollTo({top: 0,behavior: 'smooth'});
                // Store data in sessionStorage
                showSuccessNotification("Delivery details saved successfully");
                sessionStorage.setItem("deliveryInfo", JSON.stringify(formData));
                sessionStorage.removeItem("pickupInfo");
                setError("");
                closeDelivery(); // close modal if needed
                getCartList();
            })
            .catch((err) => {
                if (err.response) {
                    const errorDivCheckOut = err?.response?.data?.error?.message;
                    if (typeof errorDivCheckOut === "string") {
                        setError(err?.response?.data?.error?.message);
                    } else if (Array.isArray(errorDivCheckOut)) {
                        onErrorCheckOutTimeComper(errorDivCheckOut);
                        showToastCommon("The selected products are not available for this order. Please remove unavailable items to proceed");
                        closeDelivery();
                    }
                }
            });



    };

    useEffect(() => {
        if (deliveryInfo !== null) {
            setaddress_line1(deliveryInfo?.address_line1 || "");
            setaddress_line2(deliveryInfo?.address_line2 || "");
            // setdelivery_order_date(deliveryInfo?.delivery_order_date || "");
            // setdelivery_order_time(deliveryInfo?.delivery_order_time || "");
            setCustomerEmail(deliveryInfo?.customerEmail || "");
            setFullName(deliveryInfo?.fullName || "");
            setPhone(deliveryInfo?.phone || "");
            setLat(deliveryInfo?.lat || "");
            setLng(deliveryInfo?.lng || "");
            setorder_date(deliveryInfo?.order_date || '');
            setorder_time(deliveryInfo?.order_time || '');
            setpincode(deliveryInfo?.pincode || '')


            if (QrCodeScanAndRedirect || is_restricted) {
                setdelivery_order_date(deliveryInfo?.delivery_order_dateA || "");
                setdelivery_order_time(deliveryInfo?.delivery_order_timeA || "");
                setorder_date(deliveryInfo?.order_date || "")
                setorder_time(deliveryInfo?.order_time || "")
            } else {
                setdelivery_order_date(deliveryInfo?.delivery_order_date || "");
                setdelivery_order_time(deliveryInfo?.delivery_order_time || "");
            }

        }
    }, [deliveryInfo !== null])


    useEffect(() => {
        if (pickupInfo !== null) {
            setaddress_line1("");
            setaddress_line2("");
            // setdelivery_order_date(deliveryInfo?.delivery_order_date || "");
            // setdelivery_order_time(deliveryInfo?.delivery_order_time || "");
            setCustomerEmail("");
            setFullName("");
            setPhone("");
            setLat("");
            setLng("");
            setorder_date('');
            setorder_time('');
            setpincode('')

            if (QrCodeScanAndRedirect || is_restricted) {
                setdelivery_order_date("");
                setdelivery_order_time("");
                setorder_date("")
                setorder_time("")
            } else {
                setdelivery_order_date("");
                setdelivery_order_time("");
            }

        }
    }, [pickupInfo !== null])

    

    return (
        <>
            <ToastContainer />
            <Modal centered dialogClassName="modal-method" show={showDelivery} onHide={closeDelivery}>
                <Form >
                    <Modal.Body className='mx-1'>
                        <Row className='mt-2 mb-4'>
                            <Col lg={12} md={12} xs={12} sm={12}>
                                <h4 className='title'>Delivery Information</h4>
                            </Col>
                        </Row>

                        <Row className='scroling_'>
                            <Col lg={6} md={6} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Location {Star}</Form.Label>
                                    <PlacesAutocomplete
                                        value={address_line1}
                                        onChange={handleChangeAddress}
                                        onSelect={handleSelect}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                                            const isDisabled = QrCodeScanAndRedirect || is_restricted;

                                            return (
                                                <>
                                                    <div className="position-relative">
                                                        <Form.Group>
                                                            <Form.Control
                                                                disabled={isDisabled} // Disable the input explicitly
                                                                style={{ paddingRight: "45px" }}
                                                                {...getInputProps({
                                                                    placeholder: "Search Places...",
                                                                    required: true,
                                                                    className: "input_model location-search-input",
                                                                    disabled: isDisabled, // Pass 'disabled' directly to the input
                                                                })}
                                                            />
                                                            <span
                                                                className="position-absolute top-50 end-0 translate-middle-y me-2"
                                                                style={{
                                                                    cursor: isDisabled ? "not-allowed" : "pointer", // Update cursor style
                                                                    zIndex: 1,
                                                                }}
                                                            >
                                                                <LocationRegular className="icon_" />
                                                            </span>
                                                        </Form.Group>
                                                    </div>
                                                    {!isDisabled && (
                                                        <Form.Group>
                                                            <div className="autocomplete-dropdown-container mx-1 mt-1">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map((suggestion, index) => {
                                                                    const isActive = suggestion.active;
                                                                    const style = {
                                                                        backgroundColor: isActive ? "#C2DEDC" : "#ffffff",
                                                                        cursor: "pointer",
                                                                    };
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className: isActive ? "suggestion-item--active" : "suggestion-item",
                                                                                style,
                                                                            })}
                                                                        >
                                                                            <div onClick={handleSelect}>
                                                                                {suggestion.description}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </Form.Group>
                                                    )}
                                                </>
                                            );
                                        }}
                                    </PlacesAutocomplete>

                                    {/* {DeliveryError && (
                                        <div
                                            className="text-start my-2"
                                            style={{
                                                color: "#FF0000",
                                                alignSelf: "start",
                                                fontSize: 14,
                                                marginLeft: 10,
                                            }}
                                        >
                                            {DeliveryError}
                                        </div>
                                    )} */}
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={12}>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Address Line </Form.Label>
                                    <Form.Control
                                        id="Address line"
                                        type="text"
                                        placeholder="Address line"
                                        required
                                        style={{ paddingRight: '45px' }}
                                        value={address_line2}
                                        disabled={(QrCodeScanAndRedirect || is_restricted) ? true : false}
                                        className='input_model'
                                        onChange={(e) => setaddress_line2(e.target.value)}
                                    />
                                </Form.Group>

                            </Col>

                            <Col lg={6} md={6} xs={12}>
                                <Form.Label className='delivery_label'>Delivery Date {Star}</Form.Label>
                                <Select
                                    isSearchable={false}
                                    //   {...selectOption}
                                    isDisabled={(QrCodeScanAndRedirect || is_restricted) ? true : false}
                                    value={delivery_order_date}
                                    options={
                                        DeliveryDays?.dayList &&
                                        DeliveryDays?.dayList?.map((day) => ({
                                            label: day.label,
                                            value: day.label,
                                            id: day.id
                                        }))
                                    }
                                    onChange={(opt) => {
                                        const index = DeliveryDays?.dayList?.findIndex(day => day.label === opt.label);

                                        if (opt.value.includes("TODAY")) {
                                            setorder_date(moment().format());
                                        } else if (opt.value.includes("TOMMORROW")) {
                                            const tomorrowDate = moment().add(1, 'day');
                                            setorder_date(tomorrowDate.format());
                                        } else {
                                            setorder_date(moment(opt.value).format());
                                        }

                                        setIndexForDelievryDate(index);
                                        setdelivery_order_date(opt);
                                    }}

                                    placeholder="Select Date"
                                    styles={customSelectStyles()}
                                    className='mb-3'
                                />
                            </Col>
                            <Col lg={6} md={6} xs={12}>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Delivery Time {Star}</Form.Label>
                                    <Select
                                        isSearchable={false}
                                        isDisabled={(QrCodeScanAndRedirect || is_restricted) ? true : false}
                                        options={
                                            DeliveryTimeList[IndexForDelievryDate] &&
                                            DeliveryTimeList[IndexForDelievryDate]?.map((time) => ({
                                                label: time,
                                                value: time,
                                            }))
                                        }
                                        onChange={(opt) => {
                                            setdelivery_order_time(opt);
                                            setorder_time(opt.value)
                                        }}
                                        value={delivery_order_time}
                                        placeholder="Select Time"
                                        styles={customSelectStyles()}
                                    />
                                </Form.Group></Col>


                            <Col lg={12} md={12} xs={12} sm={12}>
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Full Name {Star}</Form.Label>
                                    <Form.Control
                                        id="full_name"
                                        type="text"
                                        placeholder="Enter your full name"
                                        required
                                        className='input_model'
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>


                            <Col lg={6} md={6} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Phone {Star}</Form.Label>
                                    <div className="position-relative">

                                        <span
                                            className="position-absolute translate-middle top-50 start-0 ms-4"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <CallRegular className='icon_' />
                                        </span>
                                        <Form.Control
                                            id="Phone number"
                                            type="number"
                                            placeholder="Enter your Phone number"
                                            required
                                            style={{ paddingLeft: '45px' }}
                                            className='input_model'
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />

                                    </div>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={12}>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label className='delivery_label'>Email {Star}</Form.Label>
                                    <div className="position-relative">
                                        <Form.Control
                                            name="email"
                                            type={"text"}
                                            placeholder="Enter your email"
                                            required
                                            style={{ paddingLeft: '45px' }}
                                            className='input_model'
                                            value={customerEmail}
                                            onChange={(e) => setCustomerEmail(e.target.value)}
                                        />
                                        <span
                                            className="position-absolute translate-middle start-0 ms-4"
                                            style={{ cursor: 'pointer', top: 22 }}
                                        >
                                            <MailRegular className='icon_' />
                                        </span>
                                    </div>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Errormessage error={error} />
                        <Row className='mt-3'>
                            <Col lg={6} md={6} xs={6}>
                                <Button type="button" className='Cancle_button' variant="outlined"
                                    onClick={(e) => {
                                        closeDelivery();
                                        setError(null);
                                        if (deliveryInfo === null) {
                                            setaddress_line1("");
                                            setaddress_line2("");
                                            setCustomerEmail("");
                                            setdelivery_order_date("");
                                            setdelivery_order_time("");
                                            setFullName("");
                                            setPhone("");
                                            setDeliveryError(null);
                                        }
                                    }}>
                                    Cancel
                                </Button >
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Button style={{
                                    backgroundColor: customRes?.button_colour ? customRes?.button_colour : "#dc3545",
                                    color: customRes?.non_highlighted_text || "#fff",
                                    opacity : DeliveryError && '0.6'
                                        }}  
                                onClick={(e) => handleSubmit(e)} type="submit"
                                    className='Aplly_button'
                                    disabled={DeliveryError ? true : false}
                                    variant="contained">
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
        </>
    );
}

export default DeliveryDetailsModel;
