import React, { useState, useCallback } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { googleMapsApiKey } from '../../CommonApi/googelApiKey';
import { useIsMobile } from '../CommonComponet/MediaQueries/mediaQueries';

const MapContainer = (props) => {

  // console.log("props" ,props)
  const [selectedPlace, setSelectedPlace] = useState({});
  const [activeMarker, setActiveMarker] = useState(null);
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const isMobile = useIsMobile()

  const onMarkerClick = useCallback((props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }, []);

  const onInfoWindowClose = useCallback(() => {
    setSelectedPlace({});
    setActiveMarker(null);
    setShowingInfoWindow(false);
  }, []);

  const mapStyles = {
    width: isMobile ? '89%' :'45%',
    height: '300px',
    border: '1px solid #999',
    borderRadius: '10px',
    margin: '0 0 20px 0',
  }

  const infoWindowStyles = {
    width: '10%',
    padding: '10px',
    textAlign: 'center',
  };

  // console.log("props?.defaultCenter" ,props.defaultCenter)

  return (
    <Map  google={props.google}
    zoom={14}
    style={mapStyles}
    initialCenter={props?.defaultCenter}>
      <Marker
        onClick={onMarkerClick}
        name={'Current location'}
      />

      <InfoWindow
        marker={activeMarker}
        visible={showingInfoWindow}
        onClose={onInfoWindowClose}
      >
        <div style={infoWindowStyles}>
            <h1>{selectedPlace.name}</h1>
          </div>
      </InfoWindow>
    </Map>
  );
};

// export default GoogleApiWrapper({apiKey: googleMapsApiKey})(MapContainer);
export default GoogleApiWrapper((props) => ({
  apiKey: props?.customRes?.map_key, // Use dynamic key or fallback to default
}))(MapContainer);
