import React from 'react'
import { QRCode } from "react-qrcode-logo";
import { extractHostname } from '../CommonComponet/UrlUtils/urlUtils';


export default function QRCodeOrder({ OrderNumber , OrderDetails}) {

      let url = extractHostname();


      // console.log("url" ,url ,OrderDetails?.unique_code ,OrderDetails?.branch_id )


      return (
            <div> <QRCode
                  value={OrderDetails 
                        && 
                  String(`${url}/order_details/${OrderDetails?.branch_id}/${OrderDetails?.unique_code}`) }
                  // size={256}
                  style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                  }}
                  level={"H"}
                  id="BRANCH_QR"
                  includeMargin={true}
                   qrStyle="squares"
            /></div>
      )
}
