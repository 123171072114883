import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CODPage.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../CommonApi/axios";
import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import { useMediaQuery } from "react-responsive";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import OrderDetailsComponent from "../Payment/OrderDetailsComponent";


function CODpage(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { customRes } = props;


  // console.log("location", location.state);
  let history = useHistory();
  const location = useLocation();

  let checkOut_storeData = JSON.parse(sessionStorage.getItem("checkOut_storeData"));

  useEffect(() => {
    window.history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, [])


  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  var pathname = window.location.href;
  var pathnamesplits = pathname.replace(/(https?:\/\/)?(www.)?/i, "").split("/");
  var url = pathnamesplits[0];

  const [OrderDetails, setOrderDetails] = useState("");

  useEffect(() => {
    getOrderDetails();
  }, []);

  function getOrderDetails() {

    const orderId = checkOut_storeData?.orderId;
    const orderType = checkOut_storeData?.orderType;
    const branchId = checkOut_storeData?.BranchID;
    if (branchId);
    api
      .get(`/client/order/getOrderDetails/${orderId}/${orderType}/${branchId}`)
      .then((res) => {
        console.log("success", res?.data?.data[0]);
        setOrderDetails(res?.data?.data[0])
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  }


  return (
    <React.Fragment>
      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container fluid>
          <OrderDetailsComponent
            OrderDetails={OrderDetails}
            extractedString={checkOut_storeData?.orderId}
            TableNo={0}
            // downloadFile={downloadFile}
            orderID={checkOut_storeData?.order_id}
            customRes={customRes}
            history={history}
            PaymentMethod={OrderDetails?.order_type === "dineIn" ? "Pay at Counter" : "Cash"}
          />
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CODpage);
