import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import api from "../../CommonApi/axios";
import moment from 'moment';
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import { ArrowSortUpFilled, ArrowSortDownFilled } from '@fluentui/react-icons';
import { Card, Col, Row } from "react-bootstrap";
import Loading from "../CommonComponet/Loading/Loading";
import "./OrderDetailsPage.css"

function OrderDetailsPage() {
      let history = useHistory();
      const { branchId ,uniquecode  } = useParams();

      console.log("uniquecode" ,uniquecode ,branchId)

      const [loading, setloading] = useState(false)
      const [TableData, setTableData] = useState([]);

      useEffect(() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);

      let orderForQr = sessionStorage.getItem("orderForQr");
      const [OrderDetails, setOrderDetails] = useState("")
      const location = useLocation();

      useEffect(() => {
            window.history.pushState(null, null, location.href);
            window.onpopstate = function (event) {
                  history.go(1);
            };
      }, [])


      useEffect(() => {
            getOrderDetails(branchId, uniquecode);
      }, [uniquecode && branchId]);


      function getOrderDetails(branchId, uniquecode) {
            setloading(true)
            api
                  .get(`/client/order/getOrderDetails/${uniquecode}/${orderForQr !== null ? orderForQr : "delivery"}/${branchId}`)
                  .then((res) => {
                        console.log("success", res?.data?.data[0]);
                        setloading(false)
                        setOrderDetails(res?.data?.data[0])
                        setTableData(res?.data?.data[0] && res?.data?.data[0]?.order_items)
                  })
                  .catch((err) => {
                        if (err.response) {
                              setloading(false)
                              toast.error(err.response.data.error.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                              });
                        }
                  });
      }


      // Arrow icons can be Unicode or custom icons
      const UpArrow = <ArrowSortUpFilled />;
      const DownArrow = <ArrowSortDownFilled />;
      const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

      // Function to handle sorting
      const handleSort = (key) => {
            let direction = 'ascending';
            if (sortConfig.key === key && sortConfig.direction === 'ascending') {
                  direction = 'descending';
            }
            setSortConfig({ key, direction });
      };

      // Sorting logic based on current configuration
      const sortedData = TableData?.length > 0 && [...TableData]?.sort((a, b) => {
            if (a[sortConfig?.key] < b[sortConfig?.key]) {
                  return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                  return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
      });


      return (
            <React.Fragment>
                  <CommonHeader />
                  <div style={{ backgroundColor: "#F0EEED", minHeight: "100vh" }}>
                        <Container fluid>
                              <Row>
                                    <Col lg={1}></Col>
                                    <Col lg={10}>
                                          <Card style={{ border: 0, borderRadius: 8 }} className="mt-4">
                                                <Card.Body >
                                                      <div
                                                            //   className="mx-3" 
                                                            style={{ flex: '1 0 auto' }}>
                                                            <Row>
                                                                  <Col lg={12} md={12} xs={12}>
                                                                        <h3 className='order_numbers'>Order No : {uniquecode}</h3>
                                                                  </Col>
                                                            </Row>
                                                            <hr style={{ opacity: 0.2, color: "#5E6278", margin: "6px 0px 0px 0px" }} />
                                                            <Row className='mt-2'>
                                                                  <Col lg={6} md={6} xs={12}>
                                                                        <h5 className='customer_info'>Customer Info</h5>
                                                                        <div className='details'>
                                                                              <div className='orderTitel'>Name: <span className='orderValue'>{OrderDetails?.name || "--"}</span></div>
                                                                              <div className='orderTitel ema'>Email: <span className='orderValue'>{OrderDetails?.email || "--"}</span></div>
                                                                              <div className='orderTitel ema'>Phone No: <span className='orderValue'>{OrderDetails?.phone_number || "--"}</span></div>
                                                                        </div>
                                                                  </Col>
                                                                  <Col lg={6} md={6} xs={12}>
                                                                        <div className='order_date'>
                                                                              Order placed date: {OrderDetails?.order_date ? `${moment(OrderDetails?.order_date).format('MMMM Do YYYY')}, ${OrderDetails?.order_time}` : "--"}
                                                                        </div>
                                                                  </Col>
                                                                  {OrderDetails?.order_notes &&
                                                                        <Col lg={6} md={6} xs={12}>
                                                                              <div className='OrderNotes'>Order Notes : <span className='orderValue'>{OrderDetails?.order_notes || "--"}</span></div>
                                                                        </Col>}
                                                            </Row>

                                                            <div className='mt-3' style={{
                                                                  maxHeight: OrderDetails?.order_notes ? 'calc(100vh - 390px)' : 'calc(100vh - 360px)', // Adjust 100px based on your button height and padding
                                                                  overflowY: 'auto'
                                                            }}>
                                                                  <table className="table table-bordered">
                                                                        <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                                                              <tr className="tableTitleHeade">
                                                                                    <th scope="col" onClick={() => handleSort('name_EN')}>
                                                                                          Product Name{" "}
                                                                                          {(sortConfig.direction === 'ascending' ? UpArrow : DownArrow)}
                                                                                    </th>
                                                                                    <th scope="col" onClick={() => handleSort('total_price')}>
                                                                                          Price ({OrderDetails?.branch?.currency}){" "}
                                                                                          {(sortConfig.direction === 'ascending' ? UpArrow : DownArrow)}
                                                                                    </th>
                                                                              </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                              {sortedData?.length > 0 ? (
                                                                                    sortedData?.map((main, index) => (
                                                                                          <tr key={index}>
                                                                                                <td style={{ background: "#fff" }}>
                                                                                                      <div className='product-name'>{main?.quantity} x {main?.name_EN}</div>
                                                                                                </td>
                                                                                                <td style={{ background: "#fff" }}>
                                                                                                      <div className="product-name">
                                                                                                            {Number(main?.total_price || 0).toFixed(2)}
                                                                                                      </div>
                                                                                                </td>
                                                                                          </tr>
                                                                                    ))
                                                                              ) : (
                                                                                    <tr>
                                                                                          <td className='text-center' colSpan={2}>No product available.</td>
                                                                                    </tr>
                                                                              )}
                                                                        </tbody>
                                                                  </table>
                                                            </div>
                                                      </div>
                                                </Card.Body>
                                          </Card>
                                    </Col>
                                    <Col lg={1}></Col>
                              </Row>

                              {loading &&
                                    <Loading type={"circular"} />
                              }
                        </Container>
                  </div>
                  <ToastContainer />
            </React.Fragment>
      );
}

export default OrderDetailsPage;