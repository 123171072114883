import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import "./BranchDetailsModel.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MapContainer from './MapContainer';
import moment from 'moment';

const BranchDetailsModel = ({ ModelShow, branchDetailsModelClose, BranchDetails_, customRes, DayAndDate }) => {

  // console.log("DayAndDate", DayAndDate);

  const address = BranchDetails_ ? BranchDetails_?.resData?.address : '';
  const lat = BranchDetails_ ? parseFloat(BranchDetails_?.resData?.lat) : 23.0120338; // Default latitude
  const lng = BranchDetails_ ? parseFloat(BranchDetails_?.resData?.long) : 72.51075399999999; // Default longitude

  const defaultCenter = {
    lat: lat,
    lng: lng
  };

  // Sorted days with their time slots for "auto" slot management
  const dayOrder = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  const sortedDays = dayOrder.map((day) => {
    const filteredTimes = DayAndDate[day]?.filter(main => main[`${day.slice(0, 3)}_start_time`]);

    return (
      <li className="ng-scope" key={day}>
        <Row>
          <Col xs={3} md={3} lg={3}>
            <b>{day.charAt(0).toUpperCase() + day.slice(1)}</b>
          </Col>
          <Col xs={9} md={9} lg={9}>
            <span>
              {filteredTimes?.length > 0 ? (
                filteredTimes?.map((main) => (
                  `${main[`${day.slice(0, 3)}_start_time`]} – ${main[`${day.slice(0, 3)}_end_time`]}`
                )).join(', ')
              ) : (
                <span>No times available</span>
              )}
            </span>
          </Col>
        </Row>
      </li>
    );
  });

  // Render manual dates if slot_management_type is "manual"
  const renderManualDates = () => {
    // Ensure manual_dates is defined before processing
    if (!DayAndDate || !DayAndDate.manual_dates || DayAndDate.manual_dates.length === 0) {
      return <li>No times available</li>;
    }
  
    // Filter out past dates and group times by date
    const groupedDates = DayAndDate?.manual_dates?.reduce((acc, date) => {
      const formattedDate = moment(date.date).format("DD-MM-YYYY");
      const todayOrFuture = moment(date.date).isSameOrAfter(moment(), 'day'); // Check if date is today or in the future
  
      if (todayOrFuture) {
        if (!acc[formattedDate]) {
          acc[formattedDate] = [];
        }
        acc[formattedDate].push(`${date.start_time} – ${date.end_time}`);
      }
      return acc;
    }, {});
  
    // If no valid dates are found, display a message
    if (Object.keys(groupedDates).length === 0) {
      return <li>No upcoming dates available</li>;
    }
  
    // Create the list items with unique dates and grouped times
    return Object.keys(groupedDates).map((date) => (
      <li key={date}>
        <Row>
          <Col xs={3} md={3} lg={3}>
            <b>{date}</b>
          </Col>
          <Col xs={9} md={9} lg={9}>
            <span>{groupedDates[date].join(', ')}</span>
          </Col>
        </Row>
      </li>
    ));
  };


  return (
    <Modal centered show={ModelShow}
      dialogClassName="modal-90w"
      onHide={branchDetailsModelClose}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header className='modal-headerModel' closeButton>
        <Modal.Title className='headername'>
          More Info
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example custom-modal-body">
        <Row>
          <Col xs={12} md={6} lg={6}>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <img
                  className="logo"
                  src={customRes?.logo_url || "/assets/media/logos/Logo_Dark.png"}
                  alt="Logo"
                  style={{ borderRadius: "7px" }}
                />
              </Col>

              <Col xs={12} md={12} lg={12}>
                <h2 className='name_branch'>{BranchDetails_ ? BranchDetails_?.resData?.branch_name_EN : ''}</h2>
              </Col>
              <Col xs={12} md={12} lg={12}>
                <h5 className='Address'>Address</h5>
              </Col>
              <Container>
                <div style={{ marginTop: "14px" }} className='underline' />
              </Container>
              <Col xs={12} md={12} lg={12}>
                <div className="dialog2-aria-describedby">
                  <LocationOnIcon /> {address}
                </div>
                <h5 className='Address'>Phone</h5>
                <div className='underline' />
                <div className='dialog2-aria-describedby'>
                  <LocalPhoneIcon /> +{BranchDetails_ ? BranchDetails_?.resData?.phone_number : ''}
                </div>
                <h5 className='Address'>Trading Hours</h5>
                <div className='underline' />
                <ul className="trading-hours ng-scope">
                  {DayAndDate?.slot_management_type === "auto" ? (
                    sortedDays
                  ) : (
                    renderManualDates()
                  )}
                </ul>
              </Col>
              <br />
            </Row>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <MapContainer defaultCenter={defaultCenter} customRes={customRes} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className='CloseButtton'
          style={{
            backgroundColor: customRes?.button_colour ?? "#dc3545",
            color: customRes?.non_highlighted_text ?? "white",
            border: 0,
          }}
          onClick={branchDetailsModelClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BranchDetailsModel;
