import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "./paymentsuccess.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import api from "../../CommonApi/axios";
import { connect } from "react-redux";
import {
  getCharges,
  setProductList,
  checkout,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import OrderDetailsComponent from "./OrderDetailsComponent";
import PaymentProcessingCard from "./PaymentProcessingCard";


function PaymentSuccess(props) {
  const { customRes } = props;
  let history = useHistory();

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const TableNo = query.get("no");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const pathname = window.location.href;
  const pathnamesplits = pathname
    .replace(/(https?:\/\/)?(www.)?/i, "")
    .split("/");
  const url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");
  const orderID = pathnamesplits[5];
  const branchId = pathnamesplits[1];


  const [extractedString, setExtractedString] = useState('');
  let orderForQr = sessionStorage.getItem("orderForQr");


  useEffect(() => {
    const pay_unicID = pathnamesplits[6];
    const myString = pay_unicID;
    const extractedString = myString?.split('#')[0];

    // Update the state with the extracted string
    setExtractedString(extractedString);
  }, [pathnamesplits]);
  const Payment = sessionStorage.getItem("Payment");
  const [OrderDetails ,setOrderDetails] = useState("")

  const [abcd, setabcd] = useState(Payment ? Payment : false);

  const location = useLocation();

  // console.log("2" ,orderID)
  // console.log("1" ,orderID1)


  useEffect(() => {
    window.history.pushState(null, null, location.href);
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, [])


  useEffect(() => {
    if (orderID && Payment === null) {
      handleSubmit();
    }
  }, [orderID && Payment === null]);

  // console.log("paymenr" ,Payment === "true");
  // sessionStorage.setItem("Payment", true);

  useEffect(() => {
    if (Payment === "true" && (extractedString && branchId)) {
      getOrderDetails(extractedString , branchId);
    }
  }, [Payment === "true" && (extractedString && branchId)]);

  function handleSubmit() {
    if (orderID) {
      let item = {
        order_id: orderID,
        status: "success",
      };
      api
        .patch(`/client/order/updateOrderStatus`, item)
        .then((res) => {
          // console.log("success", res.data);
          sessionStorage.setItem("Payment", true);
          setabcd(true);
          // getOrderDetails(extractedString , branchId);
        })
        .catch((err) => {
          if (err.response) {
            // setabcd(true);
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    }
  }

  function getOrderDetails(extractedString , branchId) {
      api
        .get(`/client/order/getOrderDetails/${extractedString}/${orderForQr !== null ? orderForQr : "delivery"}/${branchId}`)
        .then((res) => {
          console.log("success", res?.data?.data[0]);
          setOrderDetails(res?.data?.data[0])
        })
        .catch((err) => {
          if (err.response) {
            toast.error(err.response.data.error.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
  }


  return (
    <React.Fragment>
      <CommonHeader />
      <div
        style={{
          backgroundColor: "#F0EEED",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container fluid>
          {abcd === false ? (
            <PaymentProcessingCard />
          ) : (
            <OrderDetailsComponent
            OrderDetails={OrderDetails}
            extractedString={extractedString}
            TableNo={TableNo}
            // downloadFile={downloadFile}
            orderID={orderID}
            customRes={customRes}
            history={history}
            PaymentMethod={"Online"}
          />
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);