import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { CallRegular, MailRegular } from '@fluentui/react-icons';
import Button from '@mui/material/Button';
import "./DeliveryDetailsModel.css"
import { Errormessage } from '../../../CommonComponet/Error/Errormessage';
import { showSuccessNotification } from '../../../CommonComponet/Swal/Swal';



function DineInDetailsModel({ showDineIn, closeDineIn, getCartList ,customRes }) {

    let dineinInfo = JSON.parse(sessionStorage.getItem("dineinInfo")) || null;

    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [error, setError] = useState(null);

    // Form Submission Function with Validation
    const handleSubmit = (e) => {
        e.preventDefault();

        // Data to Store in Session Storage
        const formData = {
            fullName,
            phone,
            customerEmail,
            type: "dinein",
            PreTime: "25"
        };

        // console.log("ccc" ,formData)

        if (fullName || phone || customerEmail) {
            sessionStorage.setItem("dineinInfo", JSON.stringify(formData));
            showSuccessNotification("Dinein details saved successfully");
        } else {
            sessionStorage.removeItem("dineinInfo");
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        sessionStorage.removeItem("deliveryInfo");
        sessionStorage.removeItem("pickupInfo");
        setError("");
        closeDineIn(); // close modal if needed
        getCartList();

    };

    useEffect(() => {
        if (dineinInfo !== null) {
            setCustomerEmail(dineinInfo?.customerEmail || "");
            setFullName(dineinInfo?.fullName || "");
            setPhone(dineinInfo?.phone || "");
        }
    }, [dineinInfo !== null])


    return (
        <Modal centered dialogClassName="modal-method" start show={showDineIn} onHide={closeDineIn}>
            <Form >
                <Modal.Body className='mx-1'>
                    <Row className='mt-2 mb-4'>
                        <Col lg={12} md={12} xs={12} sm={12}>
                            <h4 className='title'>DineIn Information</h4>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} md={12} xs={12} sm={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label className='delivery_label'>Full Name </Form.Label>
                                <Form.Control
                                    id="Enter your full name"
                                    type={"text"}
                                    placeholder="Enter your full name"
                                    required
                                    className='input_model'
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>


                        <Col lg={6} md={6} xs={12}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='delivery_label'>Phone </Form.Label>
                                <div className="position-relative">

                                    <span
                                        className="position-absolute translate-middle top-50 start-0 ms-4"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <CallRegular className='icon_' />
                                    </span>
                                    <Form.Control
                                        id="Phone number"
                                        type={"number"}
                                        placeholder="Enter your Phone number"
                                        required
                                        style={{ paddingLeft: '45px' }}
                                        className='input_model'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg={6} md={6} xs={12}>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label className='delivery_label'>Email </Form.Label>
                                <div className="position-relative">
                                    <Form.Control
                                        id="Enter your email"
                                        type={"email"}
                                        placeholder="Enter your email"
                                        required
                                        style={{ paddingLeft: '45px' }}
                                        className='input_model'
                                        value={customerEmail}
                                        onChange={(e) => setCustomerEmail(e.target.value)}
                                    />
                                    <span
                                        className="position-absolute translate-middle start-0 ms-4"
                                        style={{ cursor: 'pointer', top: 22 }}
                                    >
                                        <MailRegular className='icon_' />
                                    </span>
                                </div>
                            </Form.Group>
                        </Col>

                    </Row>
                    <Errormessage error={error} />
                    <Row className='mt-3'>
                        <Col lg={6} md={6} xs={6}>
                            <Button type="button" className='Cancle_button' variant="outlined"
                                onClick={(e) => {
                                    closeDineIn()
                                    setError("");
                                    if (dineinInfo === null) {
                                        setCustomerEmail("");
                                        setFullName("");
                                        setPhone("");
                                    }
                                }}
                            >
                                Cancel
                            </Button >
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                            <Button style={{
                                    backgroundColor: customRes?.button_colour ? customRes?.button_colour : "#dc3545",
                                    color: customRes?.non_highlighted_text || "#fff"

                                }}  onClick={(e) => handleSubmit(e)} type="submit"
                                className='Aplly_button'
                                variant="contained">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Form>
        </Modal>
    );
}

export default DineInDetailsModel;

